import React, { useEffect, useState, useCallback } from "react";
import { Howl } from 'howler';

const UnerasablePlayer = () => {
  const [iframeError, setIframeError] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [howl, setHowl] = useState(null);
  const [progress, setProgress] = useState(0);

  const tracks = [
    {
      name: 'Forbidden',
      artist: 'HDYS',
      url: '/audio/Forbidden.mp3',
      cover: '/img/Forbidden.jpg',
      video: '/video/Forbidden.mp4'
    },
    {
      name: 'Imborrable',
      artist: 'HDYS',
      url: '/audio/Imborrable.mp3',
      cover: '/img/Imborrable.jpg'
    },
    {
      name: 'Infierno',
      artist: 'HDYS',
      url: '/audio/Infierno.mp3',
      cover: '/img/Infierno.jpg'
    },
    {
      name: 'Bunker Paradise',
      artist: 'HDYS',
      url: '/audio/BunkerParadise.mp3',
      cover: '/img/BunkerParadise.jpg'
    },
    {
      name: 'Suma y Sigue',
      artist: 'HDYS',
      url: '/audio/SumaySigue.mp3',
      cover: '/img/SumaySigue.jpg'
    },
    {
      name: 'Nada',
      artist: 'HDYS',
      url: '/audio/Nada.mp3',
      cover: '/img/Nada.jpg'
    },
    {
      name: 'We the People',
      artist: 'HDYS',
      url: '/audio/Wethepeople.mp3',
      cover: '/img/Wethepeople.jpg',
      video: '/video/Wethepeople.mp4'
    },
    {
      name: 'You make a World',
      artist: 'HDYS',
      url: '/audio/YoumakeaWorld.mp3',
      cover: '/img/YoumakeaWorld.jpg',
      video: '/video/YoumakeaWorld.mp4'
    },
    {
      name: 'Eres News',
      artist: 'HDYS',
      url: '/audio/EresNews.mp3',
      cover: '/img/EresNews.jpg'
    },
    {
      name: 'Flowers',
      artist: 'HDYS',
      url: '/audio/Flowers.mp3',
      cover: '/img/Flowers.jpg',
      video: '/video/Flowers.mp4'
    },
    {
      name: 'Almas Devoradas',
      artist: 'HDYS',
      url: '/audio/AlmasDevoradas.mp3',
      cover: '/img/AlmasDevoradas.jpg'
    },
    {
      name: 'La gana',
      artist: 'HDYS',
      url: '/audio/Lagana.mp3',
      cover: '/img/Lagana.jpg'
    },
    {
      name: 'Tengo hambre',
      artist: 'HDYS',
      url: '/audio/Tengohambre.mp3',
      cover: '/img/Tengohambre.jpg'
    },
    {
      name: 'Power Destroys',
      artist: 'HDYS',
      url: '/audio/PowerDestroys.mp3',
      cover: '/img/PowerDestroys.jpg',
      video: '/video/PowerDestroys.mp4'
    },
    {
      name: 'Avaricia',
      artist: 'HDYS',
      url: '/audio/Avaricia.mp3',
      cover: '/img/Avaricia.jpg'
    },
    {
      name: 'Drone',
      artist: 'HDYS',
      url: '/audio/Drone.mp3',
      cover: '/img/Drone.jpg'
    }
  ];

  const initializePlayer = useCallback((trackIndex, autoplay = true) => {
    if (howl) {
      howl.stop();
      howl.unload();
    }
    const track = tracks[trackIndex];
    if (!track.video) {
      const sound = new Howl({
        src: [track.url],
        html5: true,
        onend: () => {
          const nextIndex = (trackIndex + 1) % tracks.length;
          setCurrentTrackIndex(nextIndex);
          initializePlayer(nextIndex, true);
        }
      });
      setHowl(sound);
      if (autoplay) {
        sound.play();
      }
    } else {
      setHowl(null);
    }
  }, [tracks]);

  useEffect(() => {
    if (showPlayer && !howl) {
      initializePlayer(currentTrackIndex, false);
    }

    const updateProgress = () => {
      if (howl && howl.playing()) {
        const currentProgress = (howl.seek() / howl.duration()) * 100;
        setProgress(currentProgress);
      }
    };

    const progressInterval = setInterval(updateProgress, 1000);

    return () => {
      clearInterval(progressInterval);
    };
  }, [showPlayer, howl, currentTrackIndex, initializePlayer]);

  const handleSwitchToPlayer = () => {
    setShowPlayer(true);
    setIframeError(true);
    if (!howl) {
      initializePlayer(currentTrackIndex);
    }
  };

  const handlePlay = () => {
    if (howl) {
      howl.play();
    } else {
      initializePlayer(currentTrackIndex);
    }
  };

  const handlePause = () => {
    if (howl) {
      howl.pause();
    }
    const videoElement = document.querySelector('video');
    if (videoElement && !videoElement.paused) {
      videoElement.pause();
    }
  };

  const handleNext = () => {
    const nextIndex = (currentTrackIndex + 1) % tracks.length;
    setCurrentTrackIndex(nextIndex);
    initializePlayer(nextIndex, true);
  };

  const handlePrevious = () => {
    const previousIndex = (currentTrackIndex - 1 + tracks.length) % tracks.length;
    setCurrentTrackIndex(previousIndex);
    initializePlayer(previousIndex);
  };

  const handleSeek = (e) => {
    if (howl) {
      const newTime = (e.nativeEvent.offsetX / e.target.clientWidth) * howl.duration();
      howl.seek(newTime);
      setProgress((newTime / howl.duration()) * 100);
    }
  };

  return (
    <div className="Unerasable" style={{ textAlign: 'center' }}>
      {!iframeError && !showPlayer && (
        <div style={{ position: 'relative', width: '350px', height: '786px', margin: '0 auto' }}>
          <iframe
            title="Bandcamp Album"
            id="bandcamp-iframe"
            style={{ border: '0', width: '100%', height: '100%' }}
            src="https://bandcamp.com/EmbeddedPlayer/album=3811133510/size=large/bgcol=ffffff/linkcol=0687f5/transparent=true/"
            seamless
          >
            <a href="https://hdys.bandcamp.com/album/imborrable-new-album-unerasable">
              IMBORRABLE New Album UNERASABLE by HDYS
            </a>
          </iframe>
          <button onClick={handleSwitchToPlayer} style={{ marginTop: '10px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
            Switch to Local Player
          </button>
        </div>
      )}
      {(iframeError || showPlayer) && (
        <div
          id="howler-player"
          style={{
            position: 'relative',
            width: '350px',
            margin: '0 auto'
          }}
        >
          {tracks[currentTrackIndex].video ? (
            <video
              src={tracks[currentTrackIndex].video}
              controls
              autoPlay
              muted={false}
              style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
            />
          ) : (
            <img
              src={tracks[currentTrackIndex].cover}
              alt={tracks[currentTrackIndex].name}
              style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
            />
          )}
          <div style={{ marginBottom: '10px' }}>
            <strong>{tracks[currentTrackIndex].name}</strong> - {tracks[currentTrackIndex].artist}
          </div>
          <div style={{ marginBottom: '20px' }}>
            <button onClick={handlePrevious}>Previous</button>
            <button onClick={handlePlay}>Play</button>
            <button onClick={handlePause}>Pause</button>
            <button onClick={handleNext}>Next</button>
          </div>
          {!tracks[currentTrackIndex].video && (
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '10px',
                backgroundColor: '#ccc',
                cursor: 'pointer',
                marginBottom: '20px'
              }}
              onClick={handleSeek}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: `${progress}%`,
                  height: '100%',
                  backgroundColor: '#007bff'
                }}
              ></div>
            </div>
          )}
          <div style={{ textAlign: 'left' }}>
            <ul>
              {tracks.map((track, index) => (
                <li key={index} style={{ cursor: 'pointer', marginBottom: '5px' }} onClick={() => setCurrentTrackIndex(index)}>
                  {track.name} - {track.artist}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnerasablePlayer;
